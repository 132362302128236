// npm package: morris.js
// github link: https://github.com/morrisjs/morris.js

.morris-hover.morris-default-style {
  border-radius: $border-radius;
  color: $body-color;
  background: rgba($dropdown-bg, .9);
  border: 1px solid $border-color;
  font-family: $font-family-sans-serif;
  box-shadow: $dropdown-box-shadow;
}