// npm package: dropify
// github link: https://github.com/JeremyFagis/dropify

.dropify-wrapper {
  background: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  &:hover {
    background-image: linear-gradient(-45deg,darken($input-bg, 5%) 25%,transparent 25%,transparent 50%,darken($input-bg, 5%) 50%,darken($input-bg, 5%) 75%,transparent 75%,transparent)
  }
  .dropify-message {
    span {
      &.file-icon {
        font-size: .875rem;
        color: $text-muted;
        &::before {
          font-family: feather;
          content: '\e8e3';
          font-size: 24px;
        }
      }
    }
  }
  .dropify-preview {
    background-color: $input-bg;
  }
}