// npm package: flatpickr
// github link: https://github.com/flatpickr/flatpickr

.flatpickr-calendar {
  background-color: $dropdown-bg;
  border: 1px solid $dropdown-border-color;
  box-shadow: $dropdown-box-shadow;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $dropdown-border-color;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  border-top-color: $dropdown-border-color;
}

.form-control.flatpickr-input {
	background-color: $input-bg;
}

.flatpickr-day.selected, 
.flatpickr-day.startRange, 
.flatpickr-day.endRange, 
.flatpickr-day.selected.inRange, 
.flatpickr-day.startRange.inRange, 
.flatpickr-day.endRange.inRange, 
.flatpickr-day.selected:focus, 
.flatpickr-day.startRange:focus, 
.flatpickr-day.endRange:focus, 
.flatpickr-day.selected:hover, 
.flatpickr-day.startRange:hover, 
.flatpickr-day.endRange:hover, 
.flatpickr-day.selected.prevMonthDay, 
.flatpickr-day.startRange.prevMonthDay, 
.flatpickr-day.endRange.prevMonthDay, 
.flatpickr-day.selected.nextMonthDay, 
.flatpickr-day.startRange.nextMonthDay, 
.flatpickr-day.endRange.nextMonthDay {
	background: $primary;
	border-color: $primary;
}

.flatpickr-months {
	padding: 0 1rem;
	padding-top: 0.5rem;
}

.flatpickr-innerContainer {
  // padding: 0.5rem 1rem;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, 
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  
  left: 11px;
  right: auto !important;
  top: 8px;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, 
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  
  right: 11px;
  left: auto !important;
  top: 8px;
}

.flatpickr-months .flatpickr-prev-month, 
.flatpickr-months .flatpickr-next-month {
  fill: $text-muted;
}

.flatpickr-months .flatpickr-prev-month:hover svg, 
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $primary;
}

.flatpickr-months .flatpickr-month {
  height: 42px;
  color: $text-muted;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: $text-muted;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: $text-muted;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover,
.numInputWrapper:hover {
  background: rgba($primary, .1);
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 1rem;
  border-radius: $input-border-radius;
  padding: .3rem .5rem;
}

.flatpickr-weekdays {
  padding: 0 10px;
}

span.flatpickr-weekday {
  color: $text-muted;
}

.dayContainer {
  padding: 0 10px 10px;
}

.flatpickr-day {
  color: $body-color;
}

.flatpickr-day.today {
  border-color: $text-muted;
}

.flatpickr-day.flatpickr-disabled, 
.flatpickr-day.flatpickr-disabled:hover, 
.flatpickr-day.prevMonthDay, 
.flatpickr-day.nextMonthDay, 
.flatpickr-day.notAllowed, 
.flatpickr-day.notAllowed.prevMonthDay, 
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba($body-color, .5);
}

.flatpickr-day.inRange, 
.flatpickr-day.prevMonthDay.inRange, 
.flatpickr-day.nextMonthDay.inRange, 
.flatpickr-day.today.inRange, 
.flatpickr-day.prevMonthDay.today.inRange, 
.flatpickr-day.nextMonthDay.today.inRange, 
.flatpickr-day:hover, 
.flatpickr-day.prevMonthDay:hover, 
.flatpickr-day.nextMonthDay:hover, 
.flatpickr-day:focus, 
.flatpickr-day.prevMonthDay:focus, 
.flatpickr-day.nextMonthDay:focus {
  background: rgba($primary, .2);
  border-color: transparent;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top-color: $dropdown-border-color;
}

.flatpickr-time input:hover, 
.flatpickr-time .flatpickr-am-pm:hover, 
.flatpickr-time input:focus, 
.flatpickr-time .flatpickr-am-pm:focus {
  background: rgba($primary, .2);
}

.flatpickr-time input,
.flatpickr-time .flatpickr-time-separator, 
.flatpickr-time .flatpickr-am-pm {
  color: $body-color;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: $text-muted;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: $text-muted;
}